import { Action, createReducer, on } from '@ngrx/store';
import * as FeaturingActions from '@store/actions/featuring.actions';
import { CLibraryFilterData } from '@store/models';
import * as CryptoJS from 'crypto-js';
import { IContentPane } from 'lingo2-models';
import { AppState } from '..';

const thisFeatureKey = 'featuring';
export const featuringFeatureKey = thisFeatureKey;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {
  panes: IContentPane[];
}

const initialState: State = {
  panes: [],
};

const featuringReducer = createReducer(
  initialState,
  on(FeaturingActions.clearFeaturing, () => ({ ...initialState })),
  on(FeaturingActions.loadFeaturingsSuccess, (state, { featuring, key, time }) => ({
    ...state,
    panes: {
      ...state.panes,
      [key]: {
        ...featuring,
        time,
      },
    },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return featuringReducer(state, action);
}

export function makeKeyFromCLibraryFilterData(contentFilter: Partial<CLibraryFilterData>) {
  const baseRoute = contentFilter?.baseRoute;
  const subjectRoute = contentFilter?.subjectCodes.length ? contentFilter?.subjectCodes.join('-') : '';
  const typesRoute = contentFilter?.contentTypeCodes.length ? contentFilter?.contentTypeCodes.join('-') : '';
  const contentMode = contentFilter?.contentMode;
  const contentOrder = contentFilter?.contentOrder;
  const contentTerm = contentFilter?.term;
  return CryptoJS.MD5(
    `${baseRoute}#${subjectRoute}#${typesRoute}#${contentMode}#${contentOrder}#${contentTerm}`,
    'onclass',
  ).toString();
}

export const getFeaturingsByKey = (state: AppState, props: { contentFilter: Partial<CLibraryFilterData> }) => {
  const key = makeKeyFromCLibraryFilterData(props.contentFilter);

  if (!state[thisFeatureKey].panes[key] || state[thisFeatureKey].panes[key] === undefined) {
    return null;
  } else {
    return state[thisFeatureKey].panes[key];
  }
};
export const getFeaturingsAll = (state: AppState) => state[thisFeatureKey].panes;
